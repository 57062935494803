<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create New GiftCard</h1>
    </div>
    <CreateCardForm />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CreateCardForm from "@/views/cards/constituent/CreateCardForm.vue";

export default defineComponent({
  name: "CreateGiftCard",
  components: {
    CreateCardForm,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
