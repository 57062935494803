<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6 col-lg-4">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class="" @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="name" class="form-label"> Name Of Card </label>
                <InputWrapper :errors="formError.name">
                  <input
                    type="text"
                    v-model="formData.name"
                    class="form-control"
                    id="name"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="uid" class="form-label"> Unique ID Of Card </label>
                <InputWrapper :errors="formError.uid">
                  <input
                    type="text"
                    v-model="formData.uid"
                    class="form-control"
                    id="uid"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="image" class="form-label">Image file</label>
                <InputWrapper :errors="formError.image">
                  <input
                    class="form-control"
                    value=""
                    id="image"
                    type="file"
                    accept="image/png"
                    @change="SetFile"
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Gift Card</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import request from "@/utils/request";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { ShowSuccess, Toast } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";
export default defineComponent({
  name: "CreateCardForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup(_, context) {
    const formData = ref({
      name: "",
      uid: "",
    });
    const formError = ref({});
    const loading = ref(false);
    let image: File | undefined = undefined;
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        const contentData = new FormData();
        contentData.append("name", formData.value.name);
        contentData.append("uid", formData.value.uid);
        if (image) {
          console.log("Gekko");
          contentData.append("image", image, image.name);
        }
        await request.post("/admin/cards", contentData);
        context.emit("save");
        ShowSuccess("Entry Created Successfully", () => {
          formData.value = {
            name: "",
            uid: "",
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create GiftCard: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    function SetFile($event: Event) {
      const files = ($event?.target as HTMLInputElement)?.files;
      image = files?.[0];
    }
    return {
      Submit,
      SetFile,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>
