
import { defineComponent } from "vue";
import CreateCardForm from "@/views/cards/constituent/CreateCardForm.vue";

export default defineComponent({
  name: "CreateGiftCard",
  components: {
    CreateCardForm,
  },
  setup() {
    return {};
  },
});
