
import { defineComponent, ref } from "vue";
import request from "@/utils/request";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { ShowSuccess, Toast } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";
export default defineComponent({
  name: "CreateCardForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup(_, context) {
    const formData = ref({
      name: "",
      uid: "",
    });
    const formError = ref({});
    const loading = ref(false);
    let image: File | undefined = undefined;
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        const contentData = new FormData();
        contentData.append("name", formData.value.name);
        contentData.append("uid", formData.value.uid);
        if (image) {
          console.log("Gekko");
          contentData.append("image", image, image.name);
        }
        await request.post("/admin/cards", contentData);
        context.emit("save");
        ShowSuccess("Entry Created Successfully", () => {
          formData.value = {
            name: "",
            uid: "",
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create GiftCard: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    function SetFile($event: Event) {
      const files = ($event?.target as HTMLInputElement)?.files;
      image = files?.[0];
    }
    return {
      Submit,
      SetFile,
      formData,
      formError,
      loading,
    };
  },
});
